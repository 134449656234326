import React from "react";

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const Imagelist = (props) => {

    const images = props.data

    return (
        <article id={props.id} className="section imagelist">
            {
                images.map((img, i) => {
                    return(
                    <figure key={i}>
                        <img src={SERVER_URL + img.attributes.image.data.attributes.url} alt={img.attributes.alt} />
                    </figure>
                    )
                })
            }
        </article>
    )

}

export default Imagelist