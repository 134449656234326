import { useState, useEffect } from "react";
import { APIFetch } from './Components/APIFetch'
import './Css/App.scss'
import Loader from "./Components/Loader";
import HambMenu from "./Components/HambMenu";
import BannerSection from "./Containers/BannerSection";
import MintSection from "./Containers/MintSection";
import CardSection from "./Containers/CardSection";
import RoadmapSection from "./Containers/RoadmapSection";
import SimpleContent from "./Containers/SimpleContent";
import FAQSection from './Containers/FAQSection'
import ActualProjects from "./Containers/ActualProjects";
import WhoWeAre from "./Containers/Whoweare";
import ReferencesSection from "./Containers/ReferencesSection";
import Imagelist from "./Containers/Imagelist";
import FooterMenus from "./Containers/FooterMenus";
import FooterLegal from "./Containers/FooterLegal"
import TheProject from "./Containers/TheProject"

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const App = () => {

  const [ menuopen, setMenuopen ] = useState(false)

  const [hamburgermenu, setHamburgermenu] = useState(null)
  const [bannersection, setBannersection] = useState(null)
  const [mintsection, setMintsection] = useState(null)
  const [cardsection, setCardsection] = useState(null)
  const [hiwsection, setHiwsection] = useState(null)
  const [projectSection, setProjectsection] = useState(null)
  const [aprojsection, setAprojsection] = useState(null)
  const [investment, setInvestment] = useState(null)
  const [roadmapsection, setRoadmapsection] = useState(null)
  const [roadmaps, setRoadmaps] = useState(null)
  const [faqSection, setFaqsection] = useState(null)
  const [faqs, setFaqs] = useState(null)
  const [wwa, setWwa] = useState(null)
  const [refCount, setRefcount] = useState(null)
  const [reflogos, setReflogos] = useState(null)
  const [ftmenus, setFtmenus] = useState(null)
  const [ftlegal, setLegal] = useState(null)

  useEffect(() => {
    APIFetch(SERVER_URL + process.env.REACT_APP_HAMBMENU_API_ROUTE, setHamburgermenu)
    APIFetch(SERVER_URL + process.env.REACT_APP_BANNERSECTION_API_ROUTE, setBannersection)
    APIFetch(SERVER_URL + process.env.REACT_APP_MINTSECTION_API_ROUTE, setMintsection)
    APIFetch(SERVER_URL + process.env.REACT_APP_CARDSECTION_API_ROUTE, setCardsection)
    APIFetch(SERVER_URL + process.env.REACT_APP_HIWSECTION_API_ROUTE, setHiwsection)
    APIFetch(SERVER_URL + process.env.REACT_APP_THEPROJECT_API_ROUTE, setProjectsection)
    APIFetch(SERVER_URL + process.env.REACT_APP_ACTPROJS_API_ROUTE, setAprojsection)
    APIFetch(SERVER_URL + process.env.REACT_APP_INVSECTION_API_ROUTE, setInvestment)
    APIFetch(SERVER_URL + process.env.REACT_APP_ROADMAPSECTION_API_ROUTE, setRoadmapsection)
    APIFetch(SERVER_URL + process.env.REACT_APP_ROADMAPS_API_ROUTE, setRoadmaps)
    APIFetch(SERVER_URL + process.env.REACT_APP_FAQSECTION_API_ROUTE, setFaqsection)
    APIFetch(SERVER_URL + process.env.REACT_APP_FAQS_API_ROUTE, setFaqs)
    APIFetch(SERVER_URL + process.env.REACT_APP_WWASECTION_API_ROUTE, setWwa)
    APIFetch(SERVER_URL + process.env.REACT_APP_REFCOUNT_API_ROUTE, setRefcount)
    APIFetch(SERVER_URL + process.env.REACT_APP_REFLOGOS_API_ROUTE, setReflogos)
    APIFetch(SERVER_URL + process.env.REACT_APP_FTMENUS_API_ROUTE, setFtmenus)
    APIFetch(SERVER_URL + process.env.REACT_APP_LEGAL_API_ROUTE, setLegal)
  }, [])

  const handleMenu = () => {
    setMenuopen(!menuopen)
  }

  return (
    <>
      { hamburgermenu && bannersection && mintsection && cardsection && hiwsection && aprojsection && investment && roadmapsection && roadmaps && faqSection && faqs && wwa && refCount && reflogos && ftmenus && ftlegal && projectSection ? <></> : <Loader /> }
      { hamburgermenu === null ?  <p>Loading...</p> : ( <HambMenu data={ hamburgermenu.data } className={ menuopen ? 'menuopen' : null} handleMenu={handleMenu} /> )}
      <div className={`overlay ${menuopen ? 'menuopen' : ''}`} onClick={handleMenu}></div>
      <div className={`container ${menuopen ? 'menuopen' : ''}`}>
        { bannersection === null || hamburgermenu === null ?  ( <p>Loading...</p> ) : ( <BannerSection data={ bannersection.data } menuData={ hamburgermenu.data } /> )}
        { mintsection === null ?    ( <p>Loading...</p> ) : ( <MintSection data={ mintsection.data } />)}
        { cardsection === null ?    ( <p>Loading...</p> ) : ( <CardSection data={ cardsection.data } />)}
        { hiwsection === null ?     ( <p>Loading...</p> ) : ( <SimpleContent data={ hiwsection.data } id="HowItWorks" />)}
        <section id="theproject">
        { projectSection === null ? ( <p>Loading...</p> ) : ( <TheProject data={ projectSection.data } />)}
        </section>
        { aprojsection === null ?   ( <p>Loading...</p> ) : ( <ActualProjects data={ aprojsection.data } id="ActualProjects" />)}
        { investment === null ?     ( <p>Loading...</p> ) : ( <SimpleContent data={ investment.data } id="Investment" />)}
        { roadmapsection === null || roadmaps === null ? ( <p>Loading...</p> ) : ( <RoadmapSection data={ roadmapsection.data } roadmaps={ roadmaps } /> )}
        { faqSection === null || faqs === null ? ( <p>Loading...</p> ) : ( <FAQSection data={ faqSection.data } faqs={ faqs } id="FAQ" /> )}
        <section id="footertop">
          { wwa === null ?          ( <p>Loading...</p> ) : ( <WhoWeAre data={ wwa.data } id="WhoWeAre" /> )}
          { refCount === null ?     ( <p>Loading...</p> ) : ( <ReferencesSection data={refCount.data} id="References" /> ) }
        </section>
        <section id="footerbt">
          { reflogos === null ?     ( <p>Loading...</p> ) : ( <Imagelist data={reflogos.data} id="reflogocoll" /> )}
          { ftmenus === null ?      ( <p>Loading...</p> ) : ( <FooterMenus data={ftmenus.data} /> )}
          { ftlegal === null ?      ( <p>Loading...</p> ) : ( <FooterLegal data={ftlegal.data} /> )}
        </section>
      </div>
    </>
  )
}

export default App;

